/* istanbul ignore file */
import {useEffect, useMemo} from 'react';
import {DefaultButton, PrimaryButton} from '@fluentui/react';
import {EditVehicleDetailsStyles} from '.';
import {useSelector, useDispatch} from 'react-redux';
import {EditVehicleDetailsLabels, Motorcycle, emptyString} from '../../configs';
import {EditCarpoolUsageEvents, EditRegistrationUsageEvents, PageUsageEvents} from '../../configs/usageEvents';
import {TelemetryConstants} from '../../configs/constants/telemetryConstants';
import {emailReceiverAlias, createCCForCarpoolMail} from '../../utils';
import Config, {ConfigKey, apiTruckType, infoBadgeDisplayMessage, uiTruckType} from '../../configs/constants/uiConfig';
import {
  IUser,
  RootState,
  ICarpoolData,
  useUpdateVehicleDetailsMutation,
  useUpdateCarpoolMutation,
  usePostCarpoolMutation,
  useDeleteCarpoolMutation,
  IIsCarpoolError,
  setRegisterFormState,
  setVehicleDetails,
  setInfoBadgeState,
  setCarpoolError,
  resetCarpool,
  carpoolInitialState,
  setVehicleRegisterError,
  msDomain,
  deleteCarpoolMailBody,
  createCarpoolMailBody,
  deleteCarpoolMailSubject,
  createCarpoolMailSubject,
  mailTypeCarpool,
  setCarpool,
  usePostEmailMutation,
  updateVehicleRegistrationServiceAdapter,
  useGetCarpoolQuery,
  getTelemetryClient,
  IRegisteredVehicleDataItem,
  vehicleRegistrationInitialState,
  useGetConfigQuery,
  regionId,
} from '@microsoft/smart-parking-coreui';
import {IEditVehicleDetailsProcessingProps} from './EditVehicleDetails.types';

export const EditVehicleDetails: React.FC<IEditVehicleDetailsProcessingProps> = ({
  registrationId,
  fromAdmin,
  refetch,
  editRegistrationProcessingProps,
}) => {
  const {primaryButtonStyles, defaultButtonStyles} = EditVehicleDetailsStyles;

  const user: IUser = useSelector((state: RootState) => state.user);
  const carpoolDetails: ICarpoolData = useSelector((state: RootState) => state.carpool);
  const vehicleRegistration: IRegisteredVehicleDataItem[] = useSelector(
    (state: RootState) => state.vehicleRegistration,
  );
  const formData: IRegisteredVehicleDataItem = useSelector((state: RootState) => state.vehicleDetails);
  const userAlias = user.userAlias;
  const {data: configData = {}} = useGetConfigQuery(regionId);
  const [updatePostByUser] = useUpdateVehicleDetailsMutation();
  const [updateCarpoolByUser] = useUpdateCarpoolMutation();
  const [createCarpoolPostByUser] = usePostCarpoolMutation();
  const [deleteCarpoolByUser] = useDeleteCarpoolMutation();
  const [sendCarpoolEmail] = usePostEmailMutation();

  const dispatch = useDispatch();
  const {data: carpoolApiDataByUser} = useGetCarpoolQuery(
    {
      carpoolApiBaseUrl: configData[ConfigKey.CarpoolUrl] as string,
      regionId: regionId,
      carpoolApiCreateGroupUrl: configData[ConfigKey.CreateCarpoolGroupUrl] as string,
      registrationId: registrationId,
    },
    {
      skip: registrationId === undefined || registrationId === emptyString || fromAdmin === true,
    },
  );
  const carpoolErrorState: IIsCarpoolError = useSelector((state: RootState) => state.isCarpoolError);
  const telemetryClient = getTelemetryClient(
    Config.REACT_APP_API_SMARTPARKING_ENVIRONMENT,
    Config.REACT_APP_MSAL_CLIENT_ID,
    TelemetryConstants.metadata.AppName,
  );
  const isAnyFieldEdited = useSelector((state: RootState) => state.formState.isAnyFieldEdited);

  const {updatePost, updateCarpool, createCarpoolPost, deleteCarpool, carpoolApiData} = fromAdmin
    ? editRegistrationProcessingProps
    : {
        updatePost: updatePostByUser,
        updateCarpool: updateCarpoolByUser,
        createCarpoolPost: createCarpoolPostByUser,
        deleteCarpool: deleteCarpoolByUser,
        carpoolApiData: carpoolApiDataByUser,
      };

  let logProperties = useMemo(
    () => ({
      ...user,
      registrationId: registrationId,
      isAdmin: fromAdmin,
      runStatus: TelemetryConstants.status.started,
      url: window.location.href,
    }),
    [fromAdmin, registrationId, user],
  );

  const resetAllStates = () => {
    dispatch(setRegisterFormState({isFormEdit: false, isAnyFieldEdited: false}));
    dispatch(setVehicleDetails(vehicleRegistrationInitialState.initialRegisterVehicleState));
    dispatch(setInfoBadgeState(vehicleRegistrationInitialState.initialInfoBadgeResponseState));
    dispatch(setCarpoolError({isCarpoolError: false}));
    dispatch(resetCarpool(carpoolInitialState.initialCarpoolState));
    dispatch(
      setVehicleRegisterError({
        isFormSubmit: false,
        isVehicleTypeError: false,
        isMakeError: false,
        isModelError: false,
        isYearError: false,
        isColorError: false,
        isLicensePlateError: false,
        isStateError: false,
        isRegistrationTypeError: false,
        isParkingLocationError: false,
        isPolicyCheckedError: false,
        closeModal: false,
        isFirstNameError: false,
        isLastNameError: false,
      }),
    );
  };

  const onClickCancel = () => {
    telemetryClient.startTrackEvent(EditRegistrationUsageEvents.Back);
    resetAllStates();
    logProperties.runStatus = TelemetryConstants.status.success;
    telemetryClient.stopTrackEvent(EditRegistrationUsageEvents.Back, logProperties);
  };

  const onCarpoolSuccess = () => {
    dispatch(
      setInfoBadgeState({
        response: true,
        responseStatus: 'success',
        message: infoBadgeDisplayMessage.update.success,
        closeStatusModal: false,
      }),
    );
    dispatch(
      setRegisterFormState({
        isFormEdit: false,
        isAnyFieldEdited: false,
      }),
    );
    refetch();
    dispatch(setVehicleDetails(vehicleRegistrationInitialState.initialRegisterVehicleState));
    dispatch(resetCarpool(carpoolInitialState.initialCarpoolState));
    dispatch(setVehicleRegisterError(vehicleRegistrationInitialState.vehicleDetailInitialErrorState));

    logProperties.runStatus = TelemetryConstants.status.success;
  };

  const onCarpoolFailure = () => {
    dispatch(
      setInfoBadgeState({
        response: true,
        responseStatus: 'error',
        message: infoBadgeDisplayMessage.update.error,
        closeStatusModal: false,
      }),
    );

    logProperties.runStatus = TelemetryConstants.status.failed;
  };

  const sendCarpoolOperationEmail = async (isDelete: boolean) => {
    const sendToAlias = emailReceiverAlias(vehicleRegistration, registrationId);
    try {
      sendCarpoolEmail({
        emailApiBaseUrl: configData[ConfigKey.CarpoolEmailUrl] as string,
        regionId: regionId,
        requestData: {
          to: sendToAlias + msDomain,
          emailBody: isDelete
            ? deleteCarpoolMailBody()
            : createCarpoolMailBody(user.firstName ?? '', user.lastName ?? ''),
          subject: isDelete ? deleteCarpoolMailSubject : createCarpoolMailSubject,
          cc: createCCForCarpoolMail(carpoolDetails),
          mailType: mailTypeCarpool,
        },
      }).unwrap();
      telemetryClient.trackEvent(EditCarpoolUsageEvents.SendCarpoolEmailSuccess, logProperties);
    } catch (error) {
      telemetryClient.trackException({exception: error as Error}, logProperties);
      telemetryClient.trackEvent(EditCarpoolUsageEvents.SendCarpoolEmailFailure, logProperties);
    }
  };

  const handleCarpoolCreation = async (registrationId: string, licensePlate: string) => {
    try {
      await createCarpoolPost(
        fromAdmin
          ? {
              carpoolApiBaseUrl: configData[ConfigKey.CarpoolUrl] as string,
              carpoolApiCreateGroupUrlAdmin: configData[ConfigKey.CreateCarpoolGroupAdminUrl] as string,
              regionId: regionId,
              requestData: {
                ...carpoolDetails,
                groupOwnerAlias: userAlias,
                id: registrationId,
                licensePlate,
              },
            }
          : {
              carpoolApiBaseUrl: configData[ConfigKey.CarpoolUrl] as string,
              carpoolApiCreateGroupUrl: configData[ConfigKey.CreateCarpoolGroupUrl] as string,
              regionId: regionId,
              requestData: {
                ...carpoolDetails,
                groupOwnerAlias: userAlias,
                id: registrationId,
                licensePlate,
              },
            },
      ).unwrap();
      telemetryClient.trackEvent(EditCarpoolUsageEvents.CreateCarpoolRegistrationSuccess, logProperties);
      return true;
    } catch (error) {
      telemetryClient.trackException({exception: error as Error}, logProperties);
      telemetryClient.trackEvent(EditCarpoolUsageEvents.CreateCarpoolRegistrationFailure, logProperties);
      onCarpoolFailure();
      return false;
    }
  };

  const onClickSave = async () => {
    telemetryClient.startTrackEvent(EditRegistrationUsageEvents.Save);
    const editFormErrors = {
      isFirstNameError: !formData.ownerFirstName,
      isLastNameError: !formData.ownerLastName,
      isVehicleTypeError: !formData.vehicleType,
      isMakeError: !formData.make,
      isModelError: formData.vehicleType !== Motorcycle && !formData.model,
      isYearError: !formData.year,
      isColorError: !formData.color,
      isLicensePlateError: !formData.licensePlate,
      isStateError: !formData.state,
      isRegistrationTypeError: !formData.registrationType,
      isParkingLocationError: !formData.parkingLocation,
    };
    dispatch(
      setVehicleRegisterError({
        ...vehicleRegistrationInitialState.vehicleDetailInitialErrorState,
        isFormSubmit: true,
        ...editFormErrors,
      }),
    );
    const validateEditForm = (error: any) =>
      error.isFirstNameError === false &&
      error.isLastNameError === false &&
      error.isVehicleTypeError === false &&
      error.isMakeError === false &&
      error.isModelError === false &&
      error.isColorError === false &&
      error.isYearError === false &&
      error.isLicensePlateError === false &&
      error.isStateError === false &&
      error.isRegistrationTypeError === false &&
      error.isParkingLocationError === false &&
      carpoolErrorState.isCarpoolError === false;
    if (validateEditForm(editFormErrors)) {
      telemetryClient.trackCustomEvent(
        {name: EditRegistrationUsageEvents.RegistrationFormValidationSuccess},
        logProperties,
      );
      try {
        const vehicleType = formData.vehicleType === uiTruckType ? apiTruckType : formData.vehicleType;
        await updatePost(
          fromAdmin
            ? {
                apiRegistrationUrlDomain: configData[ConfigKey.VehicleRegistrationDomain] as string,
                apiRegistrationAdminUrlSubString: configData[ConfigKey.VehicleRegistrationAdminUrl] as string,
                regionId: regionId,
                requestData: updateVehicleRegistrationServiceAdapter({...formData, vehicleType, registrationId}),
              }
            : {
                apiRegistrationUrlDomain: configData[ConfigKey.VehicleRegistrationDomain] as string,
                apiRegistrationUrlSubString: configData[ConfigKey.VehicleRegistrationUrl] as string,
                regionId: regionId,
                updateRequestData: updateVehicleRegistrationServiceAdapter({...formData, vehicleType, registrationId}),
              },
        ).unwrap();
        dispatch(
          setInfoBadgeState({
            response: true,
            responseStatus: 'success',
            message: infoBadgeDisplayMessage.update.success,
            closeStatusModal: false,
          }),
        );
        if (!formData.isCarpoolChecked) {
          dispatch(setVehicleDetails(vehicleRegistrationInitialState.initialRegisterVehicleState));
          dispatch(setRegisterFormState({isFormEdit: false, isAnyFieldEdited: false}));
          refetch();
        }
        dispatch(setVehicleRegisterError(vehicleRegistrationInitialState.vehicleDetailInitialErrorState));

        telemetryClient.trackEvent(EditRegistrationUsageEvents.EditRegistrationSuccess, logProperties);

        if (formData.isCarpoolChecked) {
          if (carpoolApiData) {
            try {
              await updateCarpool(
                fromAdmin
                  ? {
                      carpoolApiBaseUrl: configData[ConfigKey.CarpoolUrl] as string,
                      carpoolApiCreateGroupUrlAdmin: configData[ConfigKey.CreateCarpoolGroupAdminUrl] as string,
                      regionId: regionId,
                      requestData: carpoolDetails,
                      registrationId: registrationId,
                    }
                  : {
                      carpoolApiBaseUrl: configData[ConfigKey.CarpoolUrl] as string,
                      carpoolApiCreateGroupUrl: configData[ConfigKey.CreateCarpoolGroupUrl] as string,
                      regionId: regionId,
                      requestData: carpoolDetails,
                      registrationId: registrationId,
                    },
              ).unwrap();
              onCarpoolSuccess();
            } catch (error) {
              onCarpoolFailure();
            }
          } else {
            const success = await handleCarpoolCreation(registrationId, formData.licensePlate);
            if (success) {
              await sendCarpoolOperationEmail(false);
              onCarpoolSuccess();
            }
          }
        } else {
          if (carpoolApiData) {
            try {
              await deleteCarpool(
                fromAdmin
                  ? {
                      carpoolApiBaseUrl: configData[ConfigKey.CarpoolUrl] as string,
                      carpoolApiCreateGroupUrlAdmin: configData[ConfigKey.CreateCarpoolGroupAdminUrl],
                      regionId: regionId,
                      registrationId: registrationId,
                    }
                  : {
                      carpoolApiBaseUrl: configData[ConfigKey.CarpoolUrl] as string,
                      carpoolApiCreateGroupUrl: configData[ConfigKey.CreateCarpoolGroupUrl],
                      regionId: regionId,
                      registrationId: registrationId,
                    },
              ).unwrap();
              telemetryClient.trackEvent(EditCarpoolUsageEvents.DeleteCarpoolRegistrationSuccess, logProperties);
              onCarpoolSuccess();
              await sendCarpoolOperationEmail(true);
            } catch (error) {
              onCarpoolFailure();
              telemetryClient.trackException({exception: error as Error}, logProperties);
              telemetryClient.trackEvent(EditCarpoolUsageEvents.DeleteCarpoolRegistrationFailure, logProperties);
            }
          }
        }
      } catch (error: any) {
        if (error?.data?.message === infoBadgeDisplayMessage.duplicate.apiError) {
          dispatch(
            setInfoBadgeState({
              response: true,
              responseStatus: 'error',
              message: infoBadgeDisplayMessage.duplicate.uiError,
              closeStatusModal: false,
            }),
          );
          telemetryClient.trackEvent(EditRegistrationUsageEvents.DuplicateRegistrationFailure, {registrationId});
        } else {
          onCarpoolFailure();
        }
      }
    } else {
      telemetryClient.trackCustomEvent(
        {name: EditRegistrationUsageEvents.RegistrationFormValidationFailure},
        logProperties,
      );
    }

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

    telemetryClient.stopTrackEvent(EditRegistrationUsageEvents.Save, logProperties);
  };

  useEffect(() => {
    const editVehicle: IRegisteredVehicleDataItem | undefined = vehicleRegistration.find(
      (value: {registrationId: string}) => value.registrationId === registrationId,
    );
    if (editVehicle) {
      dispatch(setVehicleDetails({...editVehicle, isCarpoolChecked: editVehicle?.carpoolStatus || false}));
      !editVehicle.carpoolStatus && dispatch(resetCarpool(carpoolInitialState.initialCarpoolState));
    }
    carpoolApiData && dispatch(setCarpool(carpoolApiData));
  }, [carpoolApiData, dispatch, registrationId, vehicleRegistration]);

  /*istanbul ignore next*/
  useEffect(() => {
    telemetryClient.startTrackPage(PageUsageEvents.editRegistration);
    return () => {
      telemetryClient.stopTrackPage(PageUsageEvents.editRegistration, undefined, logProperties);
    };
  }, [telemetryClient, logProperties]);

  const FormButtons = (): JSX.Element => {
    return (
      <div>
        <DefaultButton
          text={EditVehicleDetailsLabels.defaultButtonText}
          title={EditVehicleDetailsLabels.defaultButtonText}
          className={defaultButtonStyles}
          onClick={onClickCancel}
        />
        <PrimaryButton
          className={primaryButtonStyles}
          title={EditVehicleDetailsLabels.title}
          text={EditVehicleDetailsLabels.text}
          data-testid={EditVehicleDetailsLabels.testid}
          onClick={onClickSave}
          disabled={!isAnyFieldEdited}
        />
      </div>
    );
  };

  return <FormButtons />;
};
