import React, {useEffect, useState} from 'react';
import {Stack} from '@fluentui/react';
import {useDispatch, useSelector} from 'react-redux';
import {mainPanelStyles} from '.';
import {
  IUserDetails,
  DataGrid,
  FullWidthContainer,
  GridStyle,
  RegisterButton,
  CarpoolMain,
  RegistrationForm,
  Help,
  MyHubAwareness,
} from '../../components';
import {TelemetryConstants} from '../../configs';
import {EditRegistrationUsageEvents, MainPanelUsageEvents} from '../../configs/usageEvents';
import {
  IUser,
  RootState,
  useGetVehicleDetailsQuery,
  carpoolInitialState,
  setRegisterFormState,
  setVehicleRegisterError,
  resetCarpool,
  setVehicleRegistration,
  getTelemetryClient,
  regionId,
  useGetConfigQuery,
} from '@microsoft/smart-parking-coreui';
import Config, {ConfigKey} from '../../configs/constants/uiConfig';
import {EditVehicleDetails} from '../EditVehicleDetails';

export const MainPanelBody = (): JSX.Element => {
  const {root, stackItemStyles} = mainPanelStyles;
  const dispatch = useDispatch();
  const [editItemId, setEditItemId] = useState('');
  const user: IUser = useSelector((state: RootState) => state.user);
  const userAlias = user.userAlias;
  const isFormEdit = useSelector((state: RootState) => state.formState.isFormEdit);
  const {data: configData = {}} = useGetConfigQuery(regionId);

  const {
    isLoading: isVehicleDetailsLoading,
    isFetching: isVehicleDetailsFetching,
    refetch: refetchVehicleDetails,
    isSuccess,
    data,
  } = useGetVehicleDetailsQuery(
    {
      apiRegistrationUrlDomain: configData[ConfigKey.VehicleRegistrationDomain] as string,
      apiRegistrationUrlSubString: configData[ConfigKey.VehicleRegistrationUrl] as string,
      regionId: regionId,
      requestParams: userAlias,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: userAlias === undefined || userAlias === '',
    },
  );
  const telemetryClient = getTelemetryClient(
    Config.REACT_APP_API_SMARTPARKING_ENVIRONMENT,
    Config.REACT_APP_MSAL_CLIENT_ID,
    TelemetryConstants.metadata.AppName,
  );
  const logProperties = React.useMemo(
    () => ({
      ...user,
      isVehicleDetailsFetching,
      isVehicleDetailsLoading,
    }),
    [isVehicleDetailsFetching, isVehicleDetailsLoading, user],
  );
  const onEditClick = (item: IUserDetails) => {
    telemetryClient.startTrackEvent(EditRegistrationUsageEvents.EditRegistration);
    setEditItemId(item.id);
    dispatch(setRegisterFormState({isFormEdit: true, isAnyFieldEdited: false}));
    dispatch(
      setVehicleRegisterError({
        isFormSubmit: false,
        isVehicleTypeError: false,
        isMakeError: false,
        isModelError: false,
        isYearError: false,
        isColorError: false,
        isLicensePlateError: false,
        isStateError: false,
        isRegistrationTypeError: false,
        isParkingLocationError: false,
        isPolicyCheckedError: false,
        closeModal: false,
        isFirstNameError: false,
        isLastNameError: false,
      }),
    );
    window.scrollTo({left: 0, top: 0.7 * document.body.scrollHeight, behavior: 'smooth'});
    telemetryClient.stopTrackEvent(EditRegistrationUsageEvents.EditRegistration, logProperties);
  };

  useEffect(() => {
    dispatch(resetCarpool(carpoolInitialState.initialCarpoolState));
    dispatch(setRegisterFormState({isFormEdit: false, isAnyFieldEdited: false}));
  }, [dispatch]);

  useEffect(() => {
    userAlias && data && isSuccess && dispatch(setVehicleRegistration(data));
  }, [dispatch, userAlias, data, isSuccess]);

  /*istanbul ignore next*/
  useEffect(() => {
    telemetryClient.startTrackPage(MainPanelUsageEvents.root);
    return () => {
      telemetryClient.stopTrackPage(MainPanelUsageEvents.root, undefined, logProperties);
    };
  }, [telemetryClient, logProperties]);

  return (
    <Stack className={root}>
      <GridStyle gridGap={6}>
        <FullWidthContainer>
          <Stack.Item align="auto" className={stackItemStyles} aria-live="polite">
            <DataGrid onEditClick={onEditClick} />
            <RegistrationForm />
            <CarpoolMain />
            {isFormEdit ? (
              <EditVehicleDetails registrationId={editItemId} refetch={refetchVehicleDetails} />
            ) : (
              <RegisterButton
                data={data}
                isLoading={isVehicleDetailsLoading}
                refetch={refetchVehicleDetails}
                userAlias={userAlias}
                isFetching={isVehicleDetailsFetching}
              />
            )}
            <MyHubAwareness />
            <Help />
          </Stack.Item>
        </FullWidthContainer>
      </GridStyle>
    </Stack>
  );
};
